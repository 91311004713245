import { patientDTO } from "../../../entities/patient.models";
import styles from "./Demographics.module.scss";

export default function Demographics({ patient }: demographicsProps) {
  return (
    <div id={styles.demographicsWrapper}>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>Patient #</span>
        </div>
        <div className={styles.value}>
          <span>{patient.patientNumber}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>Gender</span>
        </div>
        <div className={styles.value}>
          <span>{patient.gender}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>DOB</span>
        </div>
        <div className={styles.value}>
          <span>{patient.dob}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>Primary Language</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.primaryLanguage}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>Marital Status</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.maritalStatus}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>Race</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.race}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>Home Phone</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.phoneNumber}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span> Mobile Phone</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.mobilePhone}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>Street</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.street1}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>City</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.city}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>State</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.state}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>Zip Code</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.zipCode}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>SSN</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.ssn}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>Email</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.email}</span>
        </div>
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>Internet Access</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.hasInternetAccess}</span>
        </div>{" "}
      </div>
      <div className={styles.kvpWrapper}>
        <div className={styles.key}>
          <span>PCP</span>
        </div>
        <div className={styles.value}>
          <span>{patient.ovDemographic.pcp}</span>
        </div>
      </div>
    </div>
  );
}

interface demographicsProps {
  patient: patientDTO;
}
