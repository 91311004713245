import PatientDataHeader from "./patientDataHeader/PatientDataHeader";
import PatientDataCategories from "./patientCategories/PatientDataCategories";
import styles from "./PatientDataWrapper.module.scss";
import { Outlet } from "react-router-dom";
import { useContext, useEffect } from "react";
import SelectedPatientContext from "../context/SelectedPatientContext";
import { patientDTO } from "../../entities/patient.models";

export default function PatientDataWrapper(props: any) {
  let { patient, update } = useContext(SelectedPatientContext);
  console.log("patientDTO in patientDataWrapper", patient);

  useEffect(() => {
    const checkPatientDataFromStorage = async () => {
      if (patient.patientId === 0) {
        let resp: patientDTO = await JSON.parse(
          `${sessionStorage.getItem("selectedPatient")}`
        );
        if (resp.patientId !== 0) {
          update(resp);
        }
      }
    };
    checkPatientDataFromStorage();
  }, [patient]);

  return (
    <>
      <div id={styles.patientDataWrapper}>
        <div id={styles.patientDataHeader}>
          <PatientDataHeader patientDTO={patient} />
        </div>
        <div id={styles.patientDataCategories}>
          <PatientDataCategories />
        </div>
        <div id={styles.categoryData}>
          <Outlet context={patient} />
        </div>
      </div>
    </>
  );
}
