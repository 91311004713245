// import svg from "../assets/Dual Ring-1.4s-151px.svg";
import spinner from "../assets/468.gif";
// eslint-disable-next-line no-lone-blocks
{
  /* <div
style={{
  backgroundColor: "#D5D7D8",
  height: "350px",
  width: "350px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
}}
>
<Loading />
</div> */
}
export default function Loading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          minHeight: "125px",
          minWidth: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          borderRadius: "10px",
          //border: "black solid 2px",
          margin: "2rem 2rem",
          opacity: "80%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              paddingTop: "1.5rem",
            }}
          >
            <img alt="loading" src={spinner}></img>
          </div>
          <div>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                fontSize: "2.5rem",
                paddingTop: "2rem",
                paddingBottom: "1.5rem",
              }}
            >
              Loading...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
