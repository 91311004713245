import React, { useEffect } from "react";
import "./App.css";
import SearchWrapper from "./components/search/SearchWrapper";
import Header from "./components/ui/Header";
import Footer from "./components/ui/Footer";
import { Route, Routes } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PatientDataWrapper from "./components/patientDetail/PatientDataWrapper";
import Allergies from "./components/patientDetail/categoryData/Allergies";
import Appointments from "./components/patientDetail/categoryData/Appointments";
import Demographics from "./components/patientDetail/categoryData/Demographics";
import Encounters from "./components/patientDetail/categoryData/Encounters";
import Immunizations from "./components/patientDetail/categoryData/Immunizations";
import Labs from "./components/patientDetail/categoryData/Labs";
import Medications from "./components/patientDetail/categoryData/Medications";
import Problems from "./components/patientDetail/categoryData/Problems";
import Procedures from "./components/patientDetail/categoryData/Procedures";
import Vitals from "./components/patientDetail/categoryData/Vitals";
import { patientDTO } from "./entities/patient.models";
import SelectedPatientContext from "./components/context/SelectedPatientContext";
import * as azConfig from "././AzConfig";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionType,
  IPublicClientApplication,
} from "@azure/msal-browser";

export const MainContent = () => {
  const { instance, inProgress, accounts } = useMsal();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const account = useAccount(accounts[0] || {});

  const { login, result, error } = useMsalAuthentication(
    //InteractionType.Popup,
    InteractionType.Redirect,
    azConfig.loginRequest
  );

  useEffect(() => {
    // console.log(
    //   "the main content rendered but the inProgress is something other than interaction status === none"
    // );
    // console.log("this is the msal auth hook login thing", login);
    // console.log("this is the msal auth hook instance thing", instance);
    // console.log("this is the msal auth hook result thing", result);
    // console.log("this is the status of inProgress", inProgress);

    if (inProgress === InteractionStatus.None) {
      console.log(
        instance,
        login,
        result
        //error,
      );
      //instance.loginRedirect(request);
      console.log(error, "this is the msal auth hook error");
    }
  }, []);

  const [patient, setSelectedPatient] = React.useState<patientDTO>({
    patientId: 0,
    patientNumber: "",
    firstName: "",
    lastName: "",
    middleName: "",
    dob: "",
    gender: "",
    phoneNumber: "",
    email: "",

    ovDemographic: {
      patientId: 0,
      firstName: "",
      lastName: "",
      middleName: "",
      patientNumber: "",
      dob: new Date(),
      gender: "",
      phoneNumber: "",
      email: "",
      mobilePhone: "",
      deceasedDate: new Date(),
      deactivationDate: new Date(),
      hasInternetAccess: "",
      primaryLanguage: "",
      maritalStatus: "",
      race: "",
      ethnicity: "",
      age: 0,
      patientName: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipCode: "",
      ssn: "",
      pcp: "",
    },

    ovAllergies: [],
    ovAppointments: [],
    ovEncounters: [],
    ovImmunizations: [],
    ovLabs: [],
    ovLabResults: [],
    ovMedications: [],
    ovProcedures: [],
    ovVitals: [],
    ovProblems: [],
    vAllergyReactions: [],

    labDtos: [],
  });

  const setPatient = (patient: patientDTO) => {
    //console.log("patient in app", patient);
    setSelectedPatient(patient);
  };

  return (
    <>
      <AuthenticatedTemplate>
        <SelectedPatientContext.Provider
          value={{ patient, update: setSelectedPatient }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="grid-container">
              <Header id="header" />
              <Routes>
                <Route
                  path="/"
                  element={
                    <SearchWrapper
                      selectedPatient={patient}
                      setPatient={setPatient}
                    />
                  }
                />
                <Route
                  path="/patientData/:patientId"
                  element={<PatientDataWrapper />}
                >
                  <Route path="allergies" element={<Allergies />} />
                  <Route path="appointments" element={<Appointments />} />
                  <Route
                    path="demographics"
                    element={<Demographics patient={patient} />}
                  />
                  <Route path="encounters" element={<Encounters />} />
                  <Route path="immunizations" element={<Immunizations />} />
                  <Route path="labs" element={<Labs />} />
                  <Route path="medications" element={<Medications />} />
                  <Route path="problems" element={<Problems />} />
                  <Route path="procedures" element={<Procedures />} />
                  <Route path="vitals" element={<Vitals />} />
                </Route>
              </Routes>
              <Footer id="footer" />
            </div>
          </LocalizationProvider>
        </SelectedPatientContext.Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>
          <h5 className="card-title">
            Please sign-in to see your profile information.
          </h5>
          <button type="button">Sign In</button>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};
