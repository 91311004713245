import { GridColDef } from "@mui/x-data-grid-pro";

export const allergyColumnDefs: GridColDef[] = [
  {
    field: "substance",
    headerName: "Substance",
    minWidth: 150,
    flex: 3,
    resizable: true,
  },
  { field: "type", headerName: "Type", minWidth: 150, flex: 1 },
  {
    field: "reactions",
    headerName: "Reaction(s)",
    minWidth: 150,
    flex: 3,
    resizable: true,
  },
  {
    field: "reportedDate",
    headerName: "Reported Date",
    minWidth: 150,
    flex: 1,
    resizable: true,
  },

  {
    field: "allergyStatus",
    headerName: "Status",
    minWidth: 150,
    flex: 1,
    resizable: true,
  },
  {
    field: "reviewedDate",
    headerName: "Reviewed Date",
    minWidth: 150,
    flex: 1,
    resizable: true,
  },
  {
    field: "allergyCode",
    headerName: "Code",
    minWidth: 150,
    flex: 0.5,
    resizable: true,
  },
];

export const appointmentColumnDefs: GridColDef[] = [
  {
    field: "apptDate",
    headerName: "Appt Date",
    minWidth: 150,
    flex: 1,
    resizable: true,
    valueFormatter(params) {
      return new Date(params.value).toLocaleDateString();
    },
  },
  {
    field: "class",
    headerName: "Class",
    minWidth: 150,
    flex: 0.5,
    resizable: true,
  },
  {
    field: "reason",
    headerName: "Reason(s)",
    minWidth: 150,
    flex: 1,
    resizable: true,
  },
  {
    field: "apptNote",
    headerName: "Appt Notes",
    minWidth: 150,
    flex: 1.5,
    resizable: true,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 150,
    flex: 0.25,
    resizable: true,
  },

  {
    field: "statusDesc",
    headerName: "Statue Description",
    minWidth: 150,
    flex: 1,
    resizable: true,
  },
  {
    field: "provider",
    headerName: "Provider",
    minWidth: 150,
    flex: 1,
    resizable: true,
  },
];

export const encounterColumnDefs: GridColDef[] = [
  {
    field: "encounterDate",
    headerName: "Encounter Date",
    minWidth: 150,
    flex: 1,
    resizable: true,
    valueFormatter(params) {
      return new Date(params.value).toLocaleDateString();
    },
  },
  {
    field: "reason",
    headerName: "Reason",
    minWidth: 150,
    flex: 1,
    resizable: true,
  },
  {
    field: "locationName",
    headerName: "Location",
    minWidth: 150,
    flex: 1,
    resizable: true,
  },
  {
    field: "provider",
    headerName: "Provider",
    minWidth: 150,
    flex: 1,
    resizable: true,
  },
];

export const immunizationColumnDefs: GridColDef[] = [
  {
    field: "vaccineCode",
    headerName: "Vaccine Code",
    minWidth: 150,
    flex: 0.5,
  },
  {
    field: "vaccineName",
    headerName: "Vaccine Name",
    minWidth: 150,
    flex: 2,
  },
  {
    field: "dose",
    headerName: "Dose",
    minWidth: 150,
    flex: 0.25,
  },
  {
    field: "doseStatus",
    headerName: "Dose Status",
    minWidth: 150,
    flex: 0.15,
  },
  {
    field: "adminDate",
    headerName: "Admin Date",
    minWidth: 150,
    flex: 0.5,
    valueFormatter(params) {
      return new Date(params.value).toLocaleDateString();
    },
  },

  {
    field: "comment",
    headerName: "Comments",
    minWidth: 150,
    flex: 2,
  },
  {
    field: "providerName",
    headerName: "Provider",
    minWidth: 150,
    flex: 1.5,
  },
];

export const medicationsColumnDefs: GridColDef[] = [
  {
    field: "drugName",
    headerName: "Drug Name",
    minWidth: 150,
    flex: 0.5,
  },
  {
    field: "drugCode",
    headerName: "Drug Code",
    minWidth: 150,
    flex: 0.5,
  },
  {
    field: "type",
    headerName: "Type",
    minWidth: 150,
    flex: 0.25,
  },
  {
    field: "dosage",
    headerName: "Dosage",
    minWidth: 150,
    flex: 0.25,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    minWidth: 150,
    flex: 1,
  },

  {
    field: "daysSupplied",
    headerName: "Days Supplied",
    minWidth: 150,
    flex: 0.25,
  },
  {
    field: "refills",
    headerName: "Refills",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "directions",
    headerName: "Directions",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "rxStatus",
    headerName: "RX Status",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "prescriptionDate",
    headerName: "Prescription Date",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "discontinuedDate",
    headerName: "Discontinued Date",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "providerName",
    headerName: "Provider Name",
    minWidth: 150,
    flex: 1,
  },
];

export const problemsColumnDefs: GridColDef[] = [
  {
    field: "onsetDate",
    headerName: "Onset Date",
    minWidth: 150,
    flex: 0.5,
  },
  {
    field: "resolvedDate",
    headerName: "Resolved Date",
    minWidth: 150,
    flex: 0.5,
  },
  {
    field: "ppDiagnosisCode",
    headerName: "Diagnosis Code",
    minWidth: 150,
    flex: 0.25,
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 150,
    flex: 0.25,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 150,
    flex: 0.25,
  },

  {
    field: "providerName",
    headerName: "Provider Name",
    minWidth: 150,
    flex: 1,
  },
];

export const proceduresColumnDefs: GridColDef[] = [
  {
    field: "providerName",
    headerName: "Provider Name",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "procedureCode",
    headerName: "Procedure Code",
    minWidth: 150,
    flex: 0.25,
  },

  {
    field: "description",
    headerName: "Description",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "diagCode",
    headerName: "Diagnosis Code",
    minWidth: 150,
    flex: 0.25,
  },

  {
    field: "diagDesc",
    headerName: "Diagnosis Description",
    minWidth: 150,
    flex: 3,
  },

  {
    field: "serviceDate",
    headerName: "Service Date",
    minWidth: 150,
    flex: 0.5,
    valueFormatter(params) {
      return new Date(params.value).toLocaleDateString();
    },
  },

  {
    field: "encounterSid",
    headerName: "Encounter SID",
    minWidth: 150,
    flex: 0.25,
  },
];

export const vitalsColumnDefs: GridColDef[] = [
  {
    field: "rn",
    headerName: "RN",
    minWidth: 150,
    flex: 0.1,
  },
  {
    field: "measureDate",
    headerName: "Measure Date",
    minWidth: 150,
    flex: 0.25,
  },

  {
    field: "bp",
    headerName: "BP",
    minWidth: 150,
    flex: 0.25,
  },
  {
    field: "height",
    headerName: "Height",
    minWidth: 150,
    flex: 0.25,
  },
  {
    field: "weight",
    headerName: "Weight",
    minWidth: 150,
    flex: 0.25,
  },
];
