import { LogLevel } from "@azure/msal-browser";

export const azVars = {
  clientID: process.env.REACT_APP_AZURE_CLIENT_ID || "not found",
  authority: process.env.REACT_APP_AZURE_AD_AUTHORITY || "not found",
  tenantId: process.env.REACT_APP_AZURE_AD_TENANT_ID || "not found",
  apiScope: process.env.REACT_APP_AZURE_API_ACCESS_SCOPE || "not found",
  apiClientId: process.env.REACT_APP_AZURE_API_CLIENT_ID || "not found",
};

export const msalConfig = {
  auth: {
    clientId: azVars.clientID,
    authority: azVars.authority,
    //redirectUri: window.location.origin,
    redirectUri: window.location.origin,
    scopes: ["User.Read"],
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true, //may need to turn this off...
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
  forceRefresh: true,
};

export const patientDataTokenRequest = {
  scopes: [azVars.apiScope],
  audience: [azVars.apiClientId],

  forceRefresh: true,
  //// This is the token that is used to access the patient data API
};
