import { useState } from "react";
import styles from "./PatientDataCategories.module.scss";
import { NavLink, useParams } from "react-router-dom";
import Allergies from "../categoryData/Allergies";

const PatientDataCategories = (props: any) => {
  const [activeCategory, setActiveCategory] = useState("demographics");
  let activeStyle = {
    backgroundColor: "#C4A445",
  };
  let inactiveStyle = {
    backgroundColor: "#FFFFFF",
  };
  const { patientId } = useParams();
  let routeBase = `/patientData/${patientId}`;
  //let routeBase = `api/patientData/${patientId}`;

  return (
    <>
      <div id={styles.patientDataCategoriesWrapper}>
        <p>Data Categories</p>
        <ul id={styles.patientDataCategoriesList}>
          <li>
            <NavLink
              to={`${routeBase}/allergies`}
              style={
                activeCategory === "allergies" ? activeStyle : inactiveStyle
              }
              onClick={() => setActiveCategory("allergies")}
            >
              Allergies
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${routeBase}/appointments`}
              style={
                activeCategory === "appointments" ? activeStyle : inactiveStyle
              }
              onClick={() => setActiveCategory("appointments")}
            >
              Appointments
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${routeBase}/demographics`}
              style={
                activeCategory === "demographics" ? activeStyle : inactiveStyle
              }
              onClick={() => setActiveCategory("demographics")}
            >
              Demographics
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${routeBase}/encounters`}
              style={
                activeCategory === "encounters" ? activeStyle : inactiveStyle
              }
              onClick={() => setActiveCategory("encounters")}
            >
              Encounters
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${routeBase}/immunizations`}
              style={
                activeCategory === "immunizations" ? activeStyle : inactiveStyle
              }
              onClick={() => setActiveCategory("immunizations")}
            >
              Immunizations
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${routeBase}/labs`}
              style={activeCategory === "labs" ? activeStyle : inactiveStyle}
              onClick={() => setActiveCategory("labs")}
            >
              Labs
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${routeBase}/medications`}
              style={
                activeCategory === "medications" ? activeStyle : inactiveStyle
              }
              onClick={() => setActiveCategory("medications")}
            >
              Medications
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${routeBase}/problems`}
              style={
                activeCategory === "problems" ? activeStyle : inactiveStyle
              }
              onClick={() => setActiveCategory("problems")}
            >
              Problems
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${routeBase}/procedures`}
              style={
                activeCategory === "procedures" ? activeStyle : inactiveStyle
              }
              onClick={() => setActiveCategory("procedures")}
            >
              Procedures
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${routeBase}/vitals`}
              style={activeCategory === "vitals" ? activeStyle : inactiveStyle}
              onClick={() => setActiveCategory("vitals")}
            >
              Vitals
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PatientDataCategories;
