import { useState } from "react";
import { patientSearchDTO } from "../../entities/patient.models";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./PatientSearchBar.module.scss";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers";

export default function PatientSearchBar(props: any) {
  const [dobSearch, setDobSearch] = useState<Date | null>(null);
  const [patientSearchDto, setPatientSearchDto] = useState<patientSearchDTO>({
    firstName: "",
    lastName: "",
    dob: new Date(),
    middleName: "",
    gender: "",
    patientNumber: "",
    patientId: 0,
  });

  const submitHandler = (e: any) => {
    e.preventDefault();
    props.onPatientSearch(patientSearchDto);
  };

  const genderValues: string[] = ["", "M", "F"];

  //need to handle the onSubmit for the form to hit the API.. so we need to drill down into the search index going through the search index wrapper first... so we need to pass down state/props for if the search is complete, and the list of returned patients...

  return (
    <form id="searchBar" onSubmit={submitHandler}>
      <div className={styles.heading}>
        <h2>Patient Search</h2>
      </div>
      <div className={styles.formControl}>
        <div className={styles.formGroup}>
          <label htmlFor="patientNumber">Patient #</label>
          <input
            type="text"
            id="patientNumber"
            value={patientSearchDto.patientNumber}
            onChange={(e) =>
              setPatientSearchDto({
                ...patientSearchDto,
                patientNumber: e.target.value,
              })
            }
          ></input>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            value={patientSearchDto.firstName}
            onChange={(e) =>
              setPatientSearchDto({
                ...patientSearchDto,
                firstName: e.target.value,
              })
            }
          ></input>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="middleName">Middle Name</label>
          <input
            id="middleName"
            type="text"
            value={patientSearchDto.middleName}
            onChange={(e) =>
              setPatientSearchDto({
                ...patientSearchDto,
                middleName: e.target.value,
              })
            }
          ></input>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            type="text"
            value={patientSearchDto.lastName}
            onChange={(e) =>
              setPatientSearchDto({
                ...patientSearchDto,
                lastName: e.target.value,
              })
            }
          ></input>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="dob">DOB</label>

          <DesktopDatePicker
            inputFormat="MM/dd/yyyy"
            value={dobSearch}
            onChange={(date: any) => {
              if (date != null) {
                setDobSearch(date);
                setPatientSearchDto({ ...patientSearchDto, dob: date });
              }
              console.log(date);
            }}
            renderInput={(params: any) => (
              <TextField {...params} placeholder="mm/dd/yyyy" />
            )}
            className={styles.datePicker}
            disableFuture={true}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="gender">Gender</label>
          <select
            id="gender"
            value={patientSearchDto.gender}
            onChange={(e) =>
              setPatientSearchDto({
                ...patientSearchDto,
                gender: e.target.value,
              })
            }
            className={styles.select}
          >
            {genderValues.map((value, index) => (
              <option key={index} label={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          {/* <input
            id="gender"
            type="text"
            value={patientSearchDto.gender}
            onChange={(e) =>
              setPatientSearchDto({
                ...patientSearchDto,
                gender: e.target.value,
              })
            }
          ></input> */}
        </div>
      </div>
      <div className={styles.searchButton}>
        <button type="submit">Search</button>
      </div>
    </form>
  );
}
