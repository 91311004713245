import SearchIndex from "./SearchIndex";
import PatientSearchBar from "./PatientSearchBar";
import styles from "./Search.module.scss";
import { patientSearchDTO, patientDTO } from "../../entities/patient.models";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { convertPatientSearchToFormData } from "./patientSearchDataConverter";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { azVars } from "../../AzConfig";
import { patientDataTokenRequest } from "../../AzConfig";
export default function SearchWrapper(props: any) {
  let { selectedPatient, setPatient } = props;
  //this is where we'll handle all the calls/props for patient search and displaying the data... easier to try to encapsulate it here than in a bunch of different stuff, or trying to go from the /app all the way down... separation of concerns is goooooood...
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [patientData, setPatientData] = useState<patientSearchDTO[]>([]);
  const [rowData, setRowData] = useState<patientSearchDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const [token, setToken] = useState<string>("");

  const apiScope = process.env.REACT_APP_AZURE_API_ACCESS_SCOPE;

  useEffect(() => {
    console.log("current interaction status", inProgress);
    console.log("accounts length", accounts.length);

    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      console.log("these are the accounts...", accounts);

      let req = {
        ...patientDataTokenRequest,
        account: accounts[0],
      };
      //console.log("this is the req for silent token...", req);

      instance
        .acquireTokenSilent(req)
        .then((response) => {
          //console.log("acquire silent access token success...", response);
          setToken(response.accessToken);
        })
        .catch((error) => {
          console.log("this is the error from silent token...", error);
          if (error.name === "InteractionRequiredAuthError") {
            instance.acquireTokenRedirect(req).then((response: any) => {
              sessionStorage.setItem("apiToken", response.accessToken);
              setToken(response.accessToken);
              // console.log(
              //   "this is the response from acquire token redirect...",
              //   response
              // );
            });
          }
        });
    }
  }, [inProgress, accounts, instance]);

  const handlePatientSearch = async (patientSearchDto: patientSearchDTO) => {
    setIsLoading(true);
    const formData = convertPatientSearchToFormData(patientSearchDto);
    // console.log("this is the auth header token", token);
    // console.log("this is the form data", formData);
    try {
      const resp = await axios.post(
        "https://opd-api-1.azurewebsites.net/api/patientSearch",
        formData,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      //console.log("this is the resp...", resp);
      setPatientData(resp.data);
      setRowData(resp.data);
      setIsLoading(false);
    } catch (err) {
      console.log(`Error data: ${(err as AxiosError)?.response?.data}`);
      console.log(`Error status: ${(err as AxiosError)?.response?.status}`);
      setIsLoading(false);
    }
  };

  //handle going to the patient data page... specifically landing on the demographics page.
  //need to implement all of our session stor;age logic here.. at least as far as setting the patient in the session... going to need to ponder where/when we want to re-check whats there... maybe useEffects on any of the patient data pages? we're already doing this when we hit the API for the given category data so that might make the most sense...
  const handleRowClick = (row: patientDTO) => {
    selectedPatient = row;

    console.log(
      "props value after assignment in search wrapper",
      selectedPatient
    );
    setPatient(selectedPatient);
    sessionStorage.setItem("selectedPatient", JSON.stringify(selectedPatient));
    navigate(`/patientData/${row.patientId}/demographics`);
  };

  return (
    <>
      <div id={styles.searchWrapper}>
        <div id={styles.searchBar}>
          <PatientSearchBar onPatientSearch={handlePatientSearch} />
        </div>
        <div id={styles.searchIndex}>
          <SearchIndex
            isLoading={isLoading}
            gridRows={rowData}
            onRowClick={handleRowClick}
          />
        </div>
      </div>
    </>
  );
}
