import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCellParams,
  MuiEvent,
  GridEventListener,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import styles from "./SearchIndex.module.scss";

export default function SearchIndex(props: any) {
  //need state for loading...

  // const rows: GridRowsProp = [
  //   { id: 1, patientNumber: "Hello", firstName: "World" },
  //   { id: 2, col1: "DataGridPro", col2: "is Awesome" },
  //   { id: 3, col1: "MUI", col2: "is Amazing" },
  // ];

  const columns: GridColDef[] = [
    {
      field: "patientNumber",
      headerName: "Patient #",
      minWidth: 150,
      flex: 0.5,
      resizable: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 150,
      flex: 0.5,
      resizable: true,
    },
    {
      field: "middleName",
      headerName: "Middle Name",
      minWidth: 150,
      flex: 0.25,
      resizable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 150,
      flex: 1,
      resizable: true,
    },
    {
      field: "dob",
      headerName: "DOB",
      minWidth: 150,
      flex: 1,
      resizable: true,
    },
    {
      field: "gender",
      headerName: "Gender",
      minWidth: 150,
      flex: 0.25,
      resizable: true,
    },
  ];

  const handleRowClick = (rowData: any) => {
    console.log(rowData);
    props.onRowClick(rowData);
  };

  return (
    <>
      <div className={styles.gridWrapper}>
        <DataGridPro
          getRowId={(row) => row.patientId}
          rows={props.gridRows}
          columns={columns}
          className={styles.gridContent}
          style={{ fontSize: "1.75rem" }}
          loading={props.isLoading}
          onRowClick={(rowData, event: MuiEvent<React.MouseEvent>) => {
            //event.defaultMuiPrevented = true;
            handleRowClick(rowData.row);
          }}
          // disableSelectionOnClick={true}
        />
      </div>
    </>
  );
}
