import styles from "./Header.module.scss";
import logo from "../../assets/FHC gold logosmall.jpg";
import { useLocation } from "react-router";
import { useAccount, useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

const Header = (props: any) => {
  const location = useLocation();
  const { instance, accounts } = useMsal();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const account = useAccount(accounts[0] || {});

  function signOutClickHandler(instance: IPublicClientApplication) {
    if (account) {
      const logoutRequest = {
        account: instance.getAccountByHomeId(account.homeAccountId),
        postLogoutRedirectUri: "https://blank.html",
      };
      instance.logoutRedirect(logoutRequest);
    }
  }
  // SignOutButton Component returns a button that invokes a redirect logout when clicked
  function SignOutButton() {
    // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
    const { instance } = useMsal();

    return (
      <button onClick={() => signOutClickHandler(instance)}>Sign Out</button>
    );
  }
  return (
    <header id="header">
      <div className={styles.headerLogo}>
        <a href="/">
          <img src={logo} alt="FHCSWF Logo" />
        </a>
        {location.pathname.includes("patientData") ? (
          <div className={styles.redirectButton}>
            <a href="/">
              <button className={styles.redirectButton}>Back to Search</button>
            </a>
          </div>
        ) : (
          <> </>
        )}
        <div className={styles.signOutButton}>
          <SignOutButton />
        </div>
      </div>
      {/* <div className={styles.headerGreeting}></div> */}
    </header>
  );
};

export default Header;
