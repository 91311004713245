import styles from "./Footer.module.scss";

const todaysYear = new Date().getFullYear();

const Footer = (props: any) => {
  return (
    <footer id="footer">
      <div className={styles.filler}>
        <p>FHCSWF @ {todaysYear}</p>
      </div>
    </footer>
  );
};

export default Footer;
