import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Labs.module.scss";
import { labDto } from "../../../entities/labs.models";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { labResultDTO } from "../../../entities/labs.models";
import axios from "axios";
import SelectedPatientContext from "../../context/SelectedPatientContext";
import { useMsal } from "@azure/msal-react";
import Loading from "../../../utils/Loading";

//THIS ONE HAS NESTED GRIDS...

export default function LabsDev() {
  //need to get patient from context.... patient will have the labDTOs..
  //this will be the dto's with all their nested data
  const [tableRows, setTableRows] = useState<labDto[]>([]);

  let { patientId } = useParams();
  //going to have to make our own loading stuff here...
  const [isLoading, setIsLoading] = useState(true);
  const { patient } = useContext(SelectedPatientContext);
  let fauxIdForCollapse = 0;
  const { instance, accounts, inProgress } = useMsal();
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    let req = {
      scopes: ["api://f08cbeb9-9446-44e6-a0bb-fb1875604014/access_as_user"],
      forceRefresh: true,
      account: accounts[0],
    };

    //console.log("this is the req for silent token...", req);

    instance
      .acquireTokenSilent(req)
      .then((response) => {
        console.log("acquire silent access token success...", response);
        setToken(response.accessToken);
      })
      .catch((error) => {
        console.log("this is the error from silent token...", error);
        if (error.name === "InteractionRequiredAuthError") {
          instance.acquireTokenRedirect(req).then((response: any) => {
            setToken(response.accessToken);
            // console.log(
            //   "this is the response from acquire token redirect...",
            //   response
            // );
          });
        }
      });
  }, []);

  useEffect(() => {
    async function getLabsData(patientId: string) {
      var parsed = parseInt(patientId);
      const resp = await axios.post(
        `/api/patientData/${parsed}/labs`,
        patient,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      //console.log(resp.data, "resp.data in labs");
      //console.log(resp.data.labDTOs, "resp.data.labDtos in labs");
      setTableRows(resp.data.labDTOs);
      setIsLoading(false);
    }
    if (patientId && token) getLabsData(patientId);
  }, [patientId, patient, token]);

  function Row(props: { row: labDto }) {
    const { row } = props;

    const [open, setOpen] = useState(false);
    console.log(tableRows, "tableRows in labs-row function");
    // console.log(tableRows[0].ovLabResults, "labResults in labs-row function");
    return (
      <>
        {tableRows.length > 0 ? (
          <>
            <TableRow
              sx={{ "& > *": { borderBottom: "unset" } }}
              className={styles.tableRowHeader}
            >
              <TableCell className={styles.tableRowHeaderExpandCell}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={styles.tableRowHeaderCell}
              >
                {row.testName}
              </TableCell>
              <TableCell className={styles.tableRowHeaderCell}>
                {row.providerName}
              </TableCell>
              <TableCell className={styles.tableRowHeaderCell}>
                {row.orderDate}
              </TableCell>
              <TableCell className={styles.tableRowHeaderCell}>
                {row.receiptDate}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 0.5 }}>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "1rem" }}
                      gutterBottom
                      component="div"
                      className={styles.labResultRowTitle}
                    >
                      Results
                    </Typography>
                    <Table
                      size="small"
                      aria-label="purchases"
                      className={styles.labResultRowTable}
                    >
                      <TableHead className={styles.labResultRowTableHeader}>
                        <TableRow className={styles.labResultRowTableHeaderRow}>
                          <TableCell
                            className={styles.labResultRowTableHeaderCell}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            className={styles.labResultRowTableHeaderCell}
                          >
                            Result
                          </TableCell>
                          <TableCell
                            className={styles.labResultRowTableHeaderCell}
                          >
                            Range
                          </TableCell>
                          <TableCell
                            className={styles.labResultRowTableHeaderCell}
                          >
                            Abnormal
                          </TableCell>
                          <TableCell
                            className={styles.labResultRowTableHeaderCell}
                          >
                            Result Status
                          </TableCell>
                          <TableCell
                            className={styles.labResultRowTableHeaderCell}
                          >
                            Reviewed Results
                          </TableCell>
                          <TableCell
                            className={styles.labResultRowTableHeaderCell}
                          >
                            Result Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={styles.labResultRowTableBody}>
                        {row.ovLabResults.length > 0 ? (
                          row.ovLabResults.map((labResult: labResultDTO) => (
                            <TableRow
                              key={labResult.labOrderTestSid}
                              className={styles.labResultRowTableRow}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={styles.labResultRowTableRowCell}
                              >
                                {labResult.name}
                              </TableCell>
                              <TableCell
                                className={styles.labResultRowTableRowCell}
                              >
                                {labResult.result}
                              </TableCell>
                              <TableCell
                                className={styles.labResultRowTableRowCell}
                              >
                                {labResult.range}
                              </TableCell>
                              <TableCell
                                className={styles.labResultRowTableRowCell}
                              >
                                {labResult.abnormal}
                              </TableCell>
                              <TableCell
                                className={styles.labResultRowTableRowCell}
                              >
                                {labResult.resultStatus}
                              </TableCell>
                              <TableCell
                                className={styles.labResultRowTableRowCell}
                              >
                                {labResult.reviewedResults}
                              </TableCell>
                              <TableCell
                                className={styles.labResultRowTableRowCell}
                              >
                                {labResult.resultDate}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <h4>No Results</h4>
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ) : (
          <>No labs</>
        )}
      </>
    );
  }

  return (
    <div className={styles.tableWrapper}>
      <TableContainer className={styles.tableContainer}>
        <Table aria-label="collapsible table" className={styles.table}>
          <TableHead className={styles.tableHeader}>
            <TableRow className={styles.tableHeaderRow}>
              <TableCell className={styles.tableHeaderRowCell} />
              <TableCell className={styles.tableHeaderRowCell}>
                Test Name
              </TableCell>
              <TableCell className={styles.tableHeaderRowCell}>
                Provider Name
              </TableCell>
              <TableCell className={styles.tableHeaderRowCell}>
                Order Date
              </TableCell>
              <TableCell className={styles.tableHeaderRowCell}>
                Receipt Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            {isLoading ? (
              <div>
                <Loading />
              </div>
            ) : tableRows !== null && tableRows.length > 0 ? (
              tableRows.map((row: labDto) => {
                return <Row row={row} key={(fauxIdForCollapse += 1)} />;
              })
            ) : (
              <div
                style={{
                  padding: "1rem",
                  fontSize: "2rem",
                  fontWeight: "bold",
                }}
              >
                <span>
                  <p
                    style={{
                      padding: "1rem",
                      fontSize: "2rem",
                      fontWeight: "bold",
                    }}
                  >
                    No labs
                  </p>
                </span>
              </div>
            )}

            {/* <Row /> */}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
