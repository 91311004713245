import { useMsal } from "@azure/msal-react";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SelectedPatientContext from "../../context/SelectedPatientContext";
import styles from "./CategoryData.module.scss";
import { encounterColumnDefs } from "./ColumnDefinitions";

/*
so we're getting stuff rolling but now we're hitting the unauthenticated stuff again... basically it's not passing the token, and it's making the call before the token gets back... updated some dependency array stuff with the useEffects to see if it works right, if not, we're probably going to have to grab them from the session storage object... from the "token" request, it would be "response.access_token" to access that particular property we want...

*/

export default function Encounters() {
  let { patientId } = useParams();
  let fauxId = 0;
  const [isLoading, setIsLoading] = useState(true);
  const [gridRows, setGridRows] = useState([]);
  const { patient } = useContext(SelectedPatientContext);
  const { instance, accounts, inProgress } = useMsal();
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    let req = {
      scopes: ["api://f08cbeb9-9446-44e6-a0bb-fb1875604014/access_as_user"],
      forceRefresh: true,
      account: accounts[0],
    };

    //console.log("this is the req for silent token...", req);

    instance
      .acquireTokenSilent(req)
      .then((response) => {
        console.log("acquire silent access token success...", response);
        setToken(response.accessToken);
      })
      .catch((error) => {
        console.log("this is the error from silent token...", error);
        if (error.name === "InteractionRequiredAuthError") {
          instance.acquireTokenRedirect(req).then((response: any) => {
            setToken(response.accessToken);
            // console.log(
            //   "this is the response from acquire token redirect...",
            //   response
            // );
          });
        }
      });
  }, []);

  useEffect(() => {
    async function getEncounterData(patientId: string) {
      var parsed = parseInt(patientId);
      const resp = await axios.post(
        //`/encounters`,
        `/api/patientData/${parsed}/encounters`,
        patient,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      //console.log(resp.data, "resp.data in Encounters");
      setGridRows(resp.data.ovEncounters);
      setIsLoading(false);
    }
    if (patientId && token) getEncounterData(patientId);
  }, [patientId, patient, token]);

  const columns: GridColDef[] = encounterColumnDefs;

  return (
    <>
      <div className={styles.gridWrapper}>
        <DataGridPro
          rowHeight={40}
          getRowId={(id) => (fauxId += 1)}
          rows={gridRows}
          columns={columns}
          className={styles.gridContent}
          style={{ fontSize: "1.75rem" }}
          loading={isLoading}
        />
      </div>
    </>
  );
}
