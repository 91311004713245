import { patientSearchDTO } from "../../entities/patient.models";

export function convertPatientSearchToFormData(
  patientSearch: patientSearchDTO
): FormData {
  const formData = new FormData();
  formData.append("lastName", patientSearch.lastName);
  formData.append("firstName", patientSearch.firstName);
  formData.append("middleName", patientSearch.middleName);

  formData.append("dob", new Date(patientSearch.dob).toJSON());
  formData.append("gender", patientSearch.gender);
  formData.append("patientNumber", patientSearch.patientNumber);

  return formData;
}
