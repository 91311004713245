import React from "react";
import { patientDTO } from "../../entities/patient.models";

const SelectedPatientContext = React.createContext<{
  patient: patientDTO;
  update(patient: patientDTO): void;
}>({
  patient: {
    patientId: 0,
    patientNumber: "",
    firstName: "",
    lastName: "",
    middleName: "",
    dob: "",
    gender: "",
    phoneNumber: "",
    email: "",

    ovDemographic: {
      patientId: 0,
      firstName: "",
      lastName: "",
      middleName: "",
      patientNumber: "",
      dob: new Date(),
      gender: "",
      phoneNumber: "",
      email: "",
      mobilePhone: "",
      deceasedDate: new Date(),
      deactivationDate: new Date(),
      hasInternetAccess: "",
      primaryLanguage: "",
      maritalStatus: "",
      race: "",
      ethnicity: "",
      age: 0,
      patientName: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipCode: "",
      ssn: "",
      pcp: "",
    },

    ovAllergies: [],
    ovAppointments: [],
    ovEncounters: [],
    ovImmunizations: [],
    ovLabs: [],
    ovLabResults: [],
    ovMedications: [],
    ovProcedures: [],
    ovVitals: [],
    ovProblems: [],
    vAllergyReactions: [],

    labDtos: [],
  },

  update: (patientDTO) => {},
});

export default SelectedPatientContext;
