import "./App.css";
import { LicenseInfo } from "@mui/x-license-pro";
import { MsalProvider } from "@azure/msal-react";
import { MainContent } from "./MainContent";

function App({ msalInstance }: any) {
  //for routing purposes, we need to show the patient search if a search hasn't been made yet... then we need to move to the patient detail stuff if a patient has been selected... probably going to have to scaffold out the best way to prop drill in this case, or maybe use a context for if a patient has been search or if the search has been cleared... or something to that effect.......................
  const muiKey = process.env.REACT_APP_MUI_LICENSE_KEY;
  if (muiKey) LicenseInfo.setLicenseKey(muiKey);
  const syncPush = "";
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <MainContent />
      </MsalProvider>
    </>
  );
}

export default App;
