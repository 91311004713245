import styles from "./PatientDataHeader.module.scss";

const PatientDataHeader = (props: any) => {
  let { patientDTO } = props;
  function getAge(dob: string) {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  console.log("patientDTO in patientDataHeader", patientDTO);

  let patientFullName = `${patientDTO.firstName} ${patientDTO.lastName}`;

  return (
    <>
      <div id={styles.patientDataHeaderWrapper}>
        {/* <div id={styles.patientDataHeaderPicWrapper}>
          <div id={styles.patientDataHeaderPic}>
            <img
              src="https://previews.123rf.com/images/urfandadashov/urfandadashov1805/urfandadashov180500070/100957966-photo-not-available-icon-isolated-on-white-background-vector-illustration.jpg"
              alt="No patient available"
            ></img>
          </div>
        </div> */}
        <div id={styles.patientDataHeaderDataWrapper}>
          <div id={styles.patientDataHeaderData}>
            <ul id={styles.patientDataHeaderDataList}>
              <li>
                <span>Patient Number: {patientDTO.patientNumber}</span>
              </li>
              <li>
                <span>
                  Name:{" "}
                  {patientDTO.middleName !== "" &&
                  patientDTO.middleName !== null
                    ? ` ${patientDTO.firstName} ${patientDTO.middleName} ${patientDTO.lastName}`
                    : ` ${patientFullName}`}
                </span>
              </li>
              <li>
                <span>Age: {getAge(patientDTO.dob)}</span>
              </li>
              <li>
                <span>Gender: {patientDTO.gender}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientDataHeader;
